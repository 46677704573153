<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full">
      <vs-navbar
          class="navbar-custom navbar-skelton"
          :color="navbarColor"
      >
        <div class="container flex justify-center">
          <div class="flex flex-wrap justify-start w-full">
            <div
                class="cursor-pointer flex justify-start items-center site-logo"
                style="max-height: 125px"
            >

          <logo/>

        </div>
        </div>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>


<script>

import Bookmarks            from "./components/Bookmarks.vue"
import Logo                 from "../Logo.vue"

export default {
  name: "the-navbar-horizontal",
  props: {
    logo: { type: String                                                                                                          },
    navbarType: {
      type: String,
      required: true
    }
  },
  components: {
    Logo,
    Bookmarks,
  },
  computed: {
    navbarColor() {
      let color = "#fff"
      if (this.navbarType === "sticky") color = "#f7f7f7"
      else if (this.navbarType === 'static') {
        if (this.scrollY < 50) {
          color = "#f7f7f7"
        }
      }

      this.isThemedark === "dark" ? color === "#fff" ? color = "#10163a" : color = "#262c49" : null

      return color
    },
    isThemedark()          { return this.$store.state.theme                                                                       },
    navbarStyle()          { return this.navbarType === "static" ? {transition: "all .25s ease-in-out"} : {}                      },
    navbarClasses()        { return this.scrollY > 5 && this.navbarType === "static" ? null : "d-theme-dark-light-bg shadow-none" },
    scrollY()              { return this.$store.state.scrollY                                                                     },
    verticalNavMenuWidth() { return this.$store.state.verticalNavMenuWidth                                                        },
    windowWidth()          { return this.$store.state.windowWidth                                                                 },
  }
}

</script>

