<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <footer
    class="the-footer"
    :class="classes"
  >
    <div class="container">
    <div class="flex flex-col md:flex-row md:justify-between">
      <div>
      <h3 class="text-base font-medium mb-5 text-white">
      Need help?
      </h3>
      <p v-for="(subItem, subIndex) in helpData" :key="subIndex" class="text-xs text-white font-normal flex items-start mb-3 sm:mb-1"><span class="material-icons mr-3 md:mr-4">{{subItem.icon}}</span><span v-html="subItem.text"></span></p>
      </div>
      <div class="flex justify-between md:justify-end items-end pt-20 md:pt-0">
      <div class="footer-logo"><logo/></div>
      </div>
    </div>
    </div>
  </footer>
</template>

<script>
import logo from './Logo'
export default {
  name: "the-footer",
  components: {
    logo,
  },

  data() {
    return {
      relloLogo: require('@/assets/images/logo/rellopay-logo.svg'),
      flipLogo: require('@/assets/images/logo/flippay-logo.svg'),
      appName: process.env.VUE_APP_NAME || "FlipPay",
      helpData: [
            {
              text: 'Check out our <a href="https://support.{{domain}}/knowledge" target="_blank" style="color:#00cfff;">knowledge base</a>',
              icon: "school",
            },
            {
              text: 'Have a complaint? <a href="mailto:support@{{domain}}">Email us</a>',
              icon: "email",
            },
          ],

      classes: {
        type: String
      }
    };
  },
  created(){
    for (const data of this.helpData) {
        data.text = data.text.replace(/{{domain}}/g, this.domain);
    }
  },
  computed: {
    isFlipPay() {
      return this.appName.toLowerCase().includes("flippay");
    },
    domain() {
      if (this.isFlipPay) {
        return "flippay.com.au";
      } else {
        return "rellopay.co";
      }
    },
  },
  inject: {
    components: {
      default: {
        // BuyNow
      }
    }
  }
};
</script>
<style lang="scss">
.the-footer span,.the-footer a{
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.the-footer .material-icons{
  font-size: 16px;
}
.the-footer .footer-dots{
  height: 5px;
  width: 5px;
  background: white;
  border-radius: 50%;
}
@media (max-width: 1100px) {
.the-footer .footer-dots{
  display: none !important;
}
}
@media (max-width:769px){
.the-footer .footer-items{
  padding: 0.5rem 0;
}
}
.linkIcon{
  cursor: pointer;
}
</style>
